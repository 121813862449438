import React, { useEffect, useState} from "react";
import './SimulationGalleryPage.css';
import { MainScreen } from './three/MainScreen';
import { getAllCollections } from '../../services/openseaAPI';
import { IMAGE, NFT } from "../../services/models/NFT";

const SimulationGalleryPage = () => {
  const [images, setImages] = useState(Array<IMAGE>);
  
  useEffect(() => {
    getAllCollections().then((nftList: Array<NFT>) => {
      const imagesData: Array<IMAGE> = [
        // Front
        { position: [0, 0, 1.5], rotation: [0, 0, 0], url: nftList[0]?.image_url, name: nftList[0]?.name, width: "200px", height: "300px", isOdd: false},
        
        // Back
        { position: [-1.3, 0, -2.8], rotation: [0, 0, 0], url: nftList[1]?.image_url, name: nftList[1]?.name, width: "200px", height: "300px", isOdd: true},
        { position: [0.85, 0, -2.8], rotation: [0, 0, 0], url: nftList[2]?.image_url, name: nftList[2]?.name, width: "200px", height: "300px", isOdd: true},
        { position: [-3.5, 0, -2.8], rotation: [0, 0, 0], url: nftList[10]?.image_url, name: nftList[10]?.name, width: "200px", height: "300px", isOdd: true},
        { position: [3.1, 0, -2.8], rotation: [0, 0, 0], url: nftList[9]?.image_url, name: nftList[9]?.name, width: "200px", height: "300px", isOdd: true},
       
        // Left
        { position: [-1.75, 0, -0.7], rotation: [0, Math.PI / 2.5, 0], url: nftList[3]?.image_url, name: nftList[3]?.name, width: "200px", height: "300px", isOdd: true},
        { position: [-2, 0, 1.05], rotation: [0, Math.PI / 2.5, 0], url: nftList[4]?.image_url, name: nftList[4]?.name, width: "200px", height: "300px", isOdd: true},
        { position: [-2.25, 0, 2.75], rotation: [0, Math.PI / 2.5, 0], url: nftList[5]?.image_url, name: nftList[5]?.name, width: "200px", height: "300px", isOdd: true},
        
        // Right
        { position: [1.75, 0, -0.7], rotation: [0, -Math.PI / 2.5, 0], url: nftList[6]?.image_url, name: nftList[6]?.name, width: "200px", height: "300px", isOdd: false},
        { position: [2, 0, 1.05], rotation: [0, -Math.PI / 2.5, 0], url: nftList[7]?.image_url, name: nftList[7]?.name, width: "200px", height: "300px", isOdd: false},
        { position: [2.25, 0, 2.75], rotation: [0, -Math.PI / 2.5, 0], url: nftList[8]?.image_url, name: nftList[8]?.name, width: "200px", height: "300px", isOdd: false}
      ];

      // remove front pos images
      const slicedNFts = nftList.filter((n, i) => i >= 11);
      //check for odd number
      const isOdd = (number: number) => number % 2 !== 0;

      // add right and left
      slicedNFts.forEach((nft, index) => {
        if(isOdd(index)){
          imagesData.push({ position: [(-4.5-(0.75*index)), 0, -2.8-(index*0.3)], rotation: [0, 0, 0], url: nft?.image_url, name: nft.name, width: "200px", height: "300px", isOdd: true});
        }
        if(!isOdd(index)){
          if(index === 0){
            imagesData.push({ position: [(5.2+(0.75 * index)), 0, -2.8-(index*0.3)], rotation: [0, 0, 0], url: nft?.image_url, name: nft.name, width: "200px", height: "300px" , isOdd: true});
          }else{
            imagesData.push({ position: [(5.2+(0.75 * index)), 0, -2.8-(index*0.3)], rotation: [0, 0, 0], url: nft?.image_url, name: nft.name, width: "200px", height: "300px" , isOdd: false});
          }
        }
      });
      setImages(imagesData)
    });
  }, [])
  
  return (
    <>
    <div className="simulationWrapper">
      <MainScreen images={images} />
    </div>
    </>
  );
}

export default SimulationGalleryPage;