import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Gallery.css";
import { getAllCollections } from "../../services/openseaAPI";
import { FidgetSpinner } from "react-loader-spinner";
import { NFT } from "../../services/models/NFT";
import ProgressiveImage from "react-progressive-graceful-image";

const Gallery = () => {
  const [nfts, setNFTs] = useState(Array<NFT>);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getAllCollections().then((nftList: Array<NFT>) => {
      setNFTs(nftList);
      setLoading(false);
    });
  }, []);

  return (
    <>
      <div className="gallery-container">
        {loading ? (
          <FidgetSpinner
            visible={true}
            height="400"
            width="400"
            ariaLabel="dna-loading"
            wrapperStyle={{}}
            wrapperClass="dna-wrapper"
            ballColors={["#ff0000", "#00ff00", "#0000ff"]}
            backgroundColor="#F4442E"
          />
        ) : (
          nfts?.map((art: NFT, index: number) => (
            <div key={index}>
              <Link to={`/picture/${encodeURIComponent(art.name)}`}className="image-container shine">
                <div className="image-content">
                  <ProgressiveImage
                    src={art.image_url}
                    placeholder="./colorfuel-logo.jpg"
                  >
                    {(src, loading) => (
                      <img
                        className={`image${loading ? " loading" : " loaded"}`}
                        src={src}
                        alt="nft"
                        width="200"
                        height="300"
                      />
                    )}
                  </ProgressiveImage>
                </div>
              </Link>
              <div className="image-text-box gradient">
                <h3 className={"image-text"}>{art.name.replace(/^#\d+\s*/, '')}</h3>
              </div>
            </div>
          ))
        )}
      </div>
    </>
  );
};

export default Gallery;
