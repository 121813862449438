import React from "react";
import './AboutPage.css';

const AboutPage = () => {
  
  return (
    <>
    <div className="aboutWrapper">
      <p className="about">
        This is ColorFuel,
        abstract art that layers multiple platforms and technologies in both the physical and digital world.
      </p>
    </div>
    </>
  );
}

export default AboutPage;