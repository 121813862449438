import React from "react";
import './FooterBar.css';
import SocialMediaBar from "../social-media/SocialMediaBar";


const FooterBar = () => {
    return (
      <>
        <div className="footer">
            <SocialMediaBar/>
        </div>
      </>
    );
}

export default FooterBar;
