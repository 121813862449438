import { NFT } from "./models/NFT";
import { getIPFSPinList } from "./pinataAPI";

const CHAPTER_PRIMER = 'colorfuel-primer';
const CHAPTER_UNIVERSAL = 'colorfuel-universal';
const CHAPTER_NOSTALGIA = 'colorfuel-nostalgia';
const CHAPTER_INTERLUDE = 'colorfuel-interlude';
const CHAPTER_VOYAGE = 'colorfuel-voyage';
const CHAPTER_PREDEFINED = 'colorfuel-predefined';
const CHAPTER_DEJAVU = 'colorfuel-dejavu';
const CHAPTER_MAGNITUDE = 'colorfuel-magnitude';
const CHAPTER_TRANSCENDENCE = 'colorfuel-transcendence';

const PINATA_CLOUD_URL = 'https://ivory-able-shrew-504.mypinata.cloud/ipfs/';

const options = {
  method: 'GET',
  headers: {
    accept: 'application/json',
    'X-API-KEY': `${process.env.REACT_APP_API_KEY}`
  }
};

const offerOptions = {
  method: 'POST',
  headers: {accept: 'application/json', 'content-type': 'application/json'},
  body: JSON.stringify({
    parameters: {
      orderType: 0,
      offerer: 'dddd',
      offer: [
        {itemType: 2, token: '1', identifierOrCriteria: 1, startAmount: 1, endAmount: 1}
      ],
      startTime: 1,
      endTime: 1,
      zone: '1',
      zoneHash: '1',
      salt: '1',
      conduitKey: '1',
      totalOriginalConsiderationItems: 1,
      counter: '1'
    },
    signature: '1',
    protocol_address: '1'
  })
};

// Make offer on a single colorfuel NFT
export const makeOpenSeaNFTOffer = async () => {
  await fetch('https://api.opensea.io/api/v2/orders/ethereum/seaport/offers', offerOptions)
    .then(response => response.json())
    .catch(err => console.error(err));
}

export const getAllCollections = async () => {
  let collections = new Array<Array<NFT>>();

  const startDate = new Date(2024, 0, 5);
  const todaysDate = new Date();
  const days = date_diff_indays(startDate, todaysDate);

  const numberOfNFTsAfter45 = (days / 7) | 0;
  const totalNFTsReleased = 45 + numberOfNFTsAfter45;
  const storedNfts = localStorage.getItem("nfts") ?? "";
  let nftDate = localStorage.getItem("nftDate") ?? "";
  let numberOfNFTs = 0;

  if(storedNfts !== ""){
    const parsedNFTs = JSON.parse(storedNfts);
    numberOfNFTs = parsedNFTs.flat().flat().length;
  }

  if(nftDate === ""){
    localStorage.setItem("nftDate", JSON.stringify(todaysDate.getTime() + 86400000));
    nftDate = JSON.parse(localStorage.getItem("nftDate") ?? "");
  }

  // TODO: only fetch data from the latest chapter, not all of them..?
  if(
    // Locally stored nfts have less nfts than released
    numberOfNFTs < totalNFTsReleased ||
    // No locally stored nfts
    storedNfts === "" ||
    // 1 day old cache
    JSON.parse(nftDate) < todaysDate.getTime()
    ){
    const chapterPrimer = await getOpenSeaCollection(CHAPTER_PRIMER);
    const chapterUniversal = await getOpenSeaCollection(CHAPTER_UNIVERSAL);
    const chapterNostalgia = await getOpenSeaCollection(CHAPTER_NOSTALGIA);

    // Pinata pinned chapters:
    const chapterInterlude = await getPinataPinnedCollection(CHAPTER_INTERLUDE);
    const chapterVoyage = await getPinataPinnedCollection(CHAPTER_VOYAGE);
    const chapterPredfined = await getPinataPinnedCollection(CHAPTER_PREDEFINED);
    const chapterDejavu = await getPinataPinnedCollection(CHAPTER_DEJAVU);
    const chapterMagnitude = await getPinataPinnedCollection(CHAPTER_MAGNITUDE);
    const chapterTranscendence = await getPinataPinnedCollection(CHAPTER_TRANSCENDENCE);

    collections.push(chapterTranscendence)
    collections.push(chapterMagnitude);
    collections.push(chapterDejavu)
    collections.push(chapterPredfined);
    collections.push(chapterVoyage);
    collections.push(chapterInterlude);
    collections.push(chapterNostalgia);
    collections.push(chapterUniversal);
    collections.push(chapterPrimer);

    localStorage.setItem("nftDate", JSON.stringify(todaysDate.getTime() + 86400000));
    localStorage.setItem("nfts", JSON.stringify(collections));
    collections = JSON.parse(localStorage.getItem("nfts") ?? "");
  }
  else {
    collections = JSON.parse(localStorage.getItem("nfts") ?? "");
  }

  return collections.flat().flat();
}

// Get single OpenSea collection
const getOpenSeaCollection = async (collectioName: string) => {
  const collectionRespons = await fetch(`https://api.opensea.io/v2/collection/${collectioName}/nfts?limit=100`, options)
      .then(response => response.json());
      //.catch(err => console.error(err));

  const collection: Array<NFT> = collectionRespons?.nfts?.map((NFT: {
    image_url: string;
    description: any;
    collection: any;
    name: any;
    identifier: any;
    contract: any;
}) => ({
    identifier: NFT.identifier,
    name : NFT.name,
    collection : NFT.collection,
    description: NFT.description,
    image_url: NFT.image_url,
    contract: NFT.contract
  }));

  return collection;
}

const getPinataPinnedCollection = async (chapterName: string) => {
  let collection = new Array<NFT>();
  const openSeaChapterMetadata = (await getOpenSeaCollection(chapterName)).reverse();

  const pinataList = await getIPFSPinList();
  const chapterIpfsCID = pinataList.find((n: { name: string; }) => n.name === chapterName)?.ipfs_pin_hash;
  const pinataChapterFolderURL = PINATA_CLOUD_URL + chapterIpfsCID;

  /**
   * Chapter predefined was unintentionally split into different ipfs folders
   */

  let i = 1;
  openSeaChapterMetadata.forEach(nft => {
    if(chapterName === CHAPTER_PREDEFINED){
      if(nft.identifier === "1"){
        nft.image_url = pinataChapterFolderURL + `/${i}?img-width=540&img-height=540`;
      }
      else {
        let hash = pinataList.find((n: { name: string; }) => n.name === CHAPTER_PREDEFINED + `-${i}`)?.ipfs_pin_hash;
        nft.image_url = PINATA_CLOUD_URL + hash + `/${i}?img-width=540&img-height=540`
      }
    }
    else if(chapterName === CHAPTER_DEJAVU){
      if(nft.identifier === "1"){
        nft.image_url = pinataChapterFolderURL + `/${i}?img-width=540&img-height=540`;
      }
      else {
        let hash = pinataList.find((n: { name: string; }) => n.name === CHAPTER_DEJAVU + `-${i}`)?.ipfs_pin_hash;
        nft.image_url = PINATA_CLOUD_URL + hash + `/${i}?img-width=540&img-height=540`
      }
    }
    else if(chapterName === CHAPTER_MAGNITUDE){
      if(nft.identifier === "1"){
        nft.image_url = pinataChapterFolderURL + `/${i}?img-width=540&img-height=540`;
      }
      else {
        let hash = pinataList.find((n: { name: string; }) => n.name === CHAPTER_MAGNITUDE + `-${i}`)?.ipfs_pin_hash;
        nft.image_url = PINATA_CLOUD_URL + hash + `/${i}?img-width=540&img-height=540`
      }
    }
    else if(chapterName === CHAPTER_TRANSCENDENCE){
      if(nft.identifier === "1"){
        nft.image_url = pinataChapterFolderURL + `/${i}?img-width=540&img-height=540`;
      }
      else {
        let hash = pinataList.find((n: { name: string; }) => n.name === CHAPTER_TRANSCENDENCE + `-${i}`)?.ipfs_pin_hash;
        nft.image_url = PINATA_CLOUD_URL + hash + `/${i}?img-width=540&img-height=540`
      }
    }
    else{
      nft.image_url = pinataChapterFolderURL + `/${i}?img-width=540&img-height=540`;
    }
    collection.push(nft);
    i++;
  });
  
  return collection.reverse();
}

// Private helper method to calculate number of days between two dates
const date_diff_indays = function (date1: Date, date2: Date) {
  const dt1 = new Date(date1);
  const dt2 = new Date(date2);
  return Math.floor(
    (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
      Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
      (1000 * 60 * 60 * 24)
  );
}