import { Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import Gallery from './components/gallery/Gallery.tsx';
import HeaderBar from './components/header/header-bar/HeaderBar.tsx';
import PicturePage from './components/picture/PicturePage.tsx';
import FooterBar from './components/footer/footer-bar/FooterBar';
import AboutPage from './components/about/AboutPage';
import ScrollToTop from './helpers/ScrollToTop';
import SimulationGalleryPage from './components/3dgallery/SimulationGalleryPage';

function App() {
  return (
    <div className="App">
      <HeaderBar/>
        <night-sky
          id="nightSky"
          layers="4"
          density="30"
          velocity="60"
          starcolor="#0CF">
        </night-sky>
        <Routes>
          <Route exact path="/gallery" element={<Gallery />} />
          <Route exact path="/3dgallery" element={<SimulationGalleryPage />} />
          <Route exact path="/about" element={<AboutPage />} />
          <Route exact path="/picture/:name" element={<PicturePage />}/>
          <Route exact path="/" element={<Navigate to="/gallery" />}/>
          <Route path="*" element={<Navigate to="/gallery" />}/>
        </Routes>
      <FooterBar/>
      <ScrollToTop />
    </div>
  );
}

export default App;
