import React from "react";
import './HeaderBar.css';
import {Link} from 'react-router-dom';

const HeaderBar = () => {
    return (
      <>
      <div className="header">
        <div className="headerWrapper">
        <Link className="link" to="/gallery"><h2 className="header-link-text">Gallery</h2></Link>
        <Link className="link" to="/3dgallery"><h2 className="header-link-text">Simulation</h2></Link>
        </div>
      </div>
      </>
    );
}

export default HeaderBar;