import { PINATA } from "./models/NFT";

const options = {
    method: 'GET',
    headers: {
      accept: 'application/json',
      // eslint-disable-next-line
      authorization: 'Bearer ' + `${process.env.REACT_APP_PINATA_JWT}`
    }
  };
  
export const getIPFSPinList = async () => {
    const result = await fetch('https://api.pinata.cloud/data/pinList?pageLimit=100', options)
    .then(response => response.json());
  
    const pinataPinList: Array<PINATA> = result?.rows?.map((PINATA: {
      metadata: {
        name: string;
      }
      ipfs_pin_hash: string;
      date_unpinned: string;
    }) => ({
      name: PINATA.metadata.name,
      ipfs_pin_hash: PINATA.ipfs_pin_hash,
      date_unpinned: PINATA.date_unpinned
    }));
  
    return pinataPinList.filter(p => p.date_unpinned == null);
};
