import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getAllCollections } from '../../services/openseaAPI';
import "./PicturePage.css";
import { FidgetSpinner } from "react-loader-spinner";
import openseaIcon from "../../assets/opensea-icon.svg";
import backArrowIcon from "../../assets/back-arrow.svg";
import etherscanIcon from "../../assets/etherscan-logo.svg";
import { NFT } from "../../services/models/NFT";

const PicturePage = () => {
  const routeParams = useParams();
  const [loading, setLoading] = useState(true);
  const [etherscanURL, setEtherscanURL] = useState(false);
  const [selectedNFT, setSelectedNFT] = useState<NFT | undefined>();

  useEffect(() => {
    getAllCollections().then((nfts: Array<NFT>) => {
    const nft = nfts.find((nft) => nft.name === routeParams.name);
    if(nft?.collection === 'colorfuel-universal' || nft?.collection === 'colorfuel-primer' || nft?.collection === 'colorfuel-nostalgia') {
      setEtherscanURL(true);
    }
    setSelectedNFT(nft);
    setLoading(false);
    });
   },[routeParams.name])

  return (
    <>
      <div className="pictureWrapper">
        {loading ? (
          <FidgetSpinner
            visible={true}
            height="400"
            width="400"
            ariaLabel="dna-loading"
            wrapperStyle={{}}
            wrapperClass="dna-wrapper"
            ballColors={["#ff0000", "#00ff00", "#0000ff"]}
            backgroundColor="#F4442E"
          />
        ) : (
          <>
            <div className="pictureContainer-img">
              <img
                className="picture"
                key={selectedNFT?.name}
                src={selectedNFT?.image_url}
                alt="nft"
              />
            </div>
            <div className="pictureContainer gradient">
                  <h1>{selectedNFT?.name.replace(/^#\d+\s*/, '')}</h1>
              <p>{selectedNFT?.description}</p>
              <div className="pictureButtonContainer">
                <button> {
                  etherscanURL
                  ?
                  <Link
                  className="externalLink"
                  to={`https://etherscan.io/address/${selectedNFT?.contract}`}
                  target="_blank"
                >
                  <div className="pictureButtonContent">
                    <img src={etherscanIcon} alt="etherscan icon" />
                    <p>Etherscan</p>
                  </div>
                </Link>
                :
                <Link
                className="externalLink"
                to={`https://etherscan.io/nft/${selectedNFT?.contract}/${selectedNFT?.identifier}`}
                target="_blank"
              >
                <div className="pictureButtonContent">
                  <img src={etherscanIcon} alt="etherscan icon" />
                  <p>Etherscan</p>
                </div>
              </Link>
                  }
                </button>
                <button>
                  <Link
                    className="externalLink"
                    to={`https://opensea.io/assets/ethereum/${selectedNFT?.contract}/${selectedNFT?.identifier}`}
                    target="_blank"
                  >
                    <div className="pictureButtonContent">
                      <img src={openseaIcon} alt="opensea icon" />
                      <p>OpenSea</p>
                    </div>
                  </Link>
                </button>
                <Link className="galleryLink" to="/gallery">
                  <div className="redirectGalleryButtonContainer">
                    <img src={backArrowIcon} alt="backarrow icon" />
                    <p>Gallery</p>
                  </div>
                </Link>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default PicturePage;
